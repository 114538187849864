import * as styles from './index.module.scss'

import { graphql } from 'gatsby'
import React from "react"
import Comments from '../../components/comments'
import Layout from '../../components/layout/index'
import PostHeader from '../../components/post/header'
import Promo from '../../components/promo'
import SectionsRenderer from '../../components/sections/renderer'
import Seo from '../../components/seo'
import { getField } from '../../helper.js'
import { pathTo } from '../../routes'
import { translate } from '../../translations'

const Recipe = ({ data, pageContext: { locale = 'de', pathDe, pathEn } }) => {
  const { recipe } = data
  const { preparationTimeIsoString, sections, categories, vgWortId } = recipe
  const title = getField(recipe, 'title', locale)
  const teaser = getField(recipe, 'teaser', locale)
  const metaTitle = getField(recipe, 'metaTitle', locale)
  const metaDescription = getField(recipe, 'metaDescription', locale)
  const keywords = getField(recipe, 'keywords', locale)
  const sharingImage = getField(recipe.coverImage, 'file', locale)?.src
  const visual = getField(recipe.visual, "file", locale)
  const visualMobile = getField(recipe.visualMobile, "file", locale)
  const visualMedium = getField(recipe.visualMedium, "file", locale)
  const visualLarge = getField(recipe.visualLarge, "file", locale)

  const moreRecipes = data.moreRecipes.edges.map(edge => edge.node)
  const recentRecipes = data.recentRecipes.edges.map(edge => edge.node)
  const aboutUsPage = data.aboutUsPage

  const localizedUrls = {
    de: pathDe,
    en: pathEn
  }

  return (
    <>
      <Seo
        locale={locale}
        localizedUrls={localizedUrls}
        title={metaTitle}
        sharingTitle={title}
        sharingDescription={teaser}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        keywords={keywords}
        image={sharingImage}
      />
      <Layout locale={locale} localizedUrls={localizedUrls}>
        {vgWortId && <img src={`http://vg09.met.vgwort.de/na/${vgWortId}`} height="1" width="1" border="0" />}
        <div itemScope itemType="http://schema.org/Recipe">
          <PostHeader
            title={title}
            author={recipe.author}
            aboutUsPage={aboutUsPage}
            categories={recipe?.categories}
            visual={visual}
            visualMobile={visualMobile}
            visualMedium={visualMedium}
            visualLarge={visualLarge}
            advertisement={recipe?.advertisement}
            locale={locale}
          />
          <meta itemProp="description" content={metaDescription?.metaDescription} />
          {(keywords !== null && keywords.length > 0 && <meta itemProp="keywords" content={keywords} />)}
          <meta itemProp="image" content={sharingImage} />
          {(preparationTimeIsoString && <meta itemProp="cookTime" content={preparationTimeIsoString} />)}
          <div>
            <SectionsRenderer sections={sections} locale={locale} />
          </div>
          <Comments url={pathTo({ ...recipe, locale })} title={title} />
          {(categories && categories.length > 0 &&
            <div className={styles.promo}>
              <Promo
                titleDe={`${translate('recipe.more', 'de')} ${categories[0].titleDe}`}
                titleEn={`${translate('recipe.more', 'en')} ${categories[0].titleEn}`} items={moreRecipes}
                locale={locale} />
            </div>
          )}
          <div className={styles.recent}>
            <Promo
              titleDe={translate('general.recentPosts', 'de')}
              titleEn={translate('general.recentPosts', 'en')}
              items={recentRecipes}
              locale={locale}
            />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Recipe

// TODO: clean up query
export const query = graphql`
  fragment RecipeFields on CMSRecipe {
    id
    __typename
    advertisement
    portionSize
    flavor
    preparationTimeIsoString
    difficulty
    categories {
      __typename
      titleDe
      titleEn
      slugDe
      slugEn
    }
    titleDe
    titleEn
    slugDe
    slugEn
    vgWortId
    metaTitleDe
    metaTitleEn
    metaDescriptionDe
    metaDescriptionEn
    keywordsDe
    keywordsEn
    coverImage {
      fileDe {
        src
      }
      fileEn {
        src
      }
    }
    sections {
      __typename
      ...Banner
      ...Photos
      ...RecipeCard
      ...Text
      ...YoutubePlayer
    }
    author {
      name: displayName
    }
    ...RecipeHeaderImages
  }

  query RecipesQuery($id: String!, $categoryId: String) {
    recipe: cmsRecipe(id: {eq: $id}) {
      ...RecipeFields
    }

    aboutUsPage: cmsPage(id: {eq: "Page:UGFnZS0xNDQ="}) {
      __typename
      slugDe
      slugEn
    }

    moreRecipes: allCmsRecipe(limit: 8, filter: {id: {ne: $id}, categories: {elemMatch: {id: {eq: $categoryId}}}, slugDe: {ne: null}}) {
      edges {
        node {
          ...PromoRecipe
        }
      }
    }

    recentRecipes: allCmsRecipe(filter: {slugDe: {ne: null}, coverImage: {id: {ne: null}}, publishDate: {ne: null}}, sort: {order: DESC, fields: publishDate}, limit: 8) {
      edges {
        node {
          ...PromoRecipe
        }
      }
    }
  }
`
